import React, { useEffect, useState } from "react";

import NewPost from './new_post';

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "/posts"
      );

      if (resp.status !== 200) {
        return
      }

      const postsResp = await resp.json();
      setPosts((prevState) => {
        // Only want to update local cache if the remote
        // has more or the same number of posts to prevent
        // local updates from being squashed
        //
        // But also since the server is authoritative,
        // we expire the local cache in the 'else if'
        if (prevState.length <= postsResp.length) {
          return postsResp
        } else if (prevState && (+ Date.now() - prevState[0]['time_posted'] > 30000)) {
          // "eventually consistent"
          console.log("PostSet with most recent title ", prevState[0]['title'], ' is being evicted')
          return postsResp
        }

        return prevState
      });
    };
    getPosts();

    // We want to check for new posts every once in a while
    const intervalId = setInterval(() => getPosts(), 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <h1>HireMishaBook</h1>
        <div style={{width: 50 + '%', margin: 0 + ' auto'}}>
          <NewPost setPosts={setPosts} />
          {posts && posts.map((post) => (
            <div key={post.id}>
              <article>
                <h2>{post.title}</h2>
                <h4>
                  Posted by {post.username} on { new Date(post.time_posted).toLocaleDateString("en-US") } </h4>
                <p>{post.content}</p>
                <hr/>
              </article>
            </div>
          ))}
        </div>
    </div>
  );
};

export default Posts;
