// Basically following along with https://developers.cloudflare.com/pages/tutorials/build-an-api-with-workers
import './App.css';
import { Router } from "@reach/router";
import Posts from './components/posts';
const NotFound = () => <p>404 Not found</p>

function App() {
  return (
    <Router>
      <Posts path="/" />
      <NotFound default />
    </Router>
  );
}

export default App;
