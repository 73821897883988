import React from 'react';

import { v4 as uuidv4 } from 'uuid';

class NewPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = { username: '', title: '', content: '' };

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setPosts = props.setPosts
    }

    // Might be an easier way to do this than copy pasting 3 times
    // but we're going for a speed run here
    handleUsernameChange(event) { this.setState({ username: event.target.value }); }
    handleTitleChange(event) { this.setState({ title: event.target.value }); }
    handleContentChange(event) { this.setState({ content: event.target.value }); }
    async handleSubmit(event) {
        event.preventDefault();

        var data = {
            title: this.state.title,
            username: this.state.username,
            content: this.state.content
        }

        try {
            const resp = await fetch('/posts', {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });

            if (resp.status !== 200) {
                return;
            }
        } catch(e) {
            console.log('Request threw an error', e)
            return;
        }

        const timestamp = (+ new Date())
        data['id'] = 'post:' + timestamp + ':' + uuidv4()
        data['time_posted'] = timestamp

        this.setPosts((prevPosts) => {
            var newPosts = [data, ...prevPosts];
            return newPosts
        })

        this.setState({
            title: '',
            username: '',
            content: ''
        })
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label>
                    New Post:<br />
                    <div style={{ width: '50%' }}>
                        <div>
                            <input style={{ width: '100%' }} placeholder="Username" value={this.state.username} onChange={this.handleUsernameChange} required />
                        </div>
                        <div>
                            <input style={{ width: '100%' }} placeholder="Title" value={this.state.title} onChange={this.handleTitleChange} required />
                        </div>
                        <div>
                            <textarea style={{ width: '100%' }} placeholder="Content" value={this.state.content} onChange={this.handleContentChange} required />
                        </div>
                    </div>
                    <input type="submit" value="Submit Post" />
                </label>
            </form>
        );
    }
}


export default NewPost;
